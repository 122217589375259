.featured-overlay {
    position: absolute;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    background: rgba(33, 198, 138, 0.8);
    padding: 24px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
