.row {
    &.row-lg {
        margin: -7px -15px;
        [class^='col'] {
            padding: 7px 15px;
        }
    }

    &.row-blog {
        margin: 0 -15px;
        [class^='col'] {
            padding: 0 15px;
        }
    }
}

.vertical-column {
    &__item:not(:last-child) {
        padding-bottom: 16px;
    }
}

.space-vertical {
    padding-top: 16px;
    padding-bottom: 16px;
}

.line {
    border-color: #f3f3f3;
}
