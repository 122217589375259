::placeholder {
    color: $input-placeholder-color;
}

:-ms-input-placeholder {
    color: $input-placeholder-color;
}

::-ms-input-placeholder {
    color: $input-placeholder-color;
}
