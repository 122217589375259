$theme-colors: (
    'primary': #f25e5e,
    'secondary': #21c68a,
    'tertiary': #11998e,
    'danger': #f2547a,
    'dark': #3a4759,
    'info': #f1fffa,
);

$spacers: (
    0: 0,
    1: 8px,
    2: 16px,
    3: 32px,
    4: 64px,
    5: 128px,
);

$color-primary: #f25e5e;
$color-secondary: #21c68a;
$color-tertiary: #11998e;
$color-info: #f1fffa;
$color-dark: #3a4759;
$navbar-padding-y: 36px;
$navbar-dark-color: white;
$body-color: #888;
$border-color: #f6f6f6;
$card-border-radius: 16px;
$alert-border-radius: 16px;
$border-radius-lg: 16px;
$btn-border-radius-lg: 0.3rem;
$list-group-border-radius: 16px;
$font-weight-base: 300;
$headings-font-weight: 700;
$headings-font-family: 'Museo-Sans-Bold', sans-serif;
$font-family-base: 'Museo-Sans', sans-serif;
$box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.1);
$input-placeholder-color: #e1e1e1;
