.section {
    min-height: 100vh;

    &-header {
        background: transparent
            linear-gradient(
                to bottom,
                rgba(33, 198, 138, 1) 0%,
                rgba(13, 198, 180, 0) 100%
            );
    }
}

.section-header {
    &__card {
        height: 320px;
        color: white;
        border: none;
        box-shadow: 0px 5px 30px rgba(21, 198, 163, 0.5);
        background: url('../img/buble-1.png') 2% 25% no-repeat,
            url('../img/buble-2.png') 97% 90% no-repeat,
            url('../img/buble-3.png') 35% 65% no-repeat,
            url('../img/buble-4.png') 80% 10% no-repeat,
            linear-gradient(
                253deg,
                rgba(33, 198, 138, 1) 0%,
                rgba(13, 198, 180, 1) 100%
            );
    }

    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__img {
        position: absolute;
        bottom: 0;
        right: 5%;
        width: 80px;
    }

    &__title {
        font-size: 24px;
    }

    &__btn {
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__btn-wrapper {
        margin: 0;
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.section-feature {
    text-align: center;
}

.section-register {
    &__card {
        height: 140px;
        background: $color-info;
        border: none;
    }

    &__number {
        width: 42px;
        height: 42px;
        background: $color-primary;
        border: 6px solid #fff2f2;
        color: #ffffff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        margin: 0 auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
}

.section-package {
    &__card {
        height: 460px;
    }

    &__card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.section-bottom {
    &__card {
        @extend .section-header__card;
        margin-bottom: -160px;

        background: url('../img/buble-1.png') 2% 25% no-repeat,
            url('../img/buble-2.png') 97% 90% no-repeat,
            url('../img/buble-4.png') 80% 10% no-repeat,
            linear-gradient(
                253deg,
                rgba(33, 198, 138, 1) 0%,
                rgba(13, 198, 180, 1) 100%
            );
    }

    &__footer {
        padding-top: 240px;
        color: #ffffff;
        border-bottom: 8px solid $color-secondary;
        background: $color-dark url('../img/footer.png') center center no-repeat;
    }

    &__img {
        @extend .section-header__img;
        left: 10%;
        display: none;
    }

    &__copy {
        text-align: center;
    }
}
