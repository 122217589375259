.slick-slide {
    .section-feature__img {
        display: inline-block;
    }

    .section-register__img {
        @extend .section-feature__img;
    }
}

.card-scroll {
    .slick-next {
        display: none;
        right: 0;
    }

    .slick-prev {
        left: 0;
        display: none;
    }
}

.slick-list {
    padding-left: 0px !important;
}

.blog-scroll {
    .slick-track {
        padding: 2rem 0;
    }
}

.list-group-item {
    &.active {
        z-index: 2;
        color: $color-secondary;
        background-color: #ffffff;
        border-color: rgba(0, 0, 0, 0.125);

        .icon {
            filter: invert(57%) sepia(95%) saturate(348%) hue-rotate(107deg)
                brightness(92%) contrast(93%);
        }
    }
}
