@include fontFace('Museo-Sans', '../fonts/museosans-300', 300);
@include fontFace('Museo-Sans-Semibold', '../fonts/museosans-500', 500);
@include fontFace('Museo-Sans-Bold', '../fonts/museosans-700', 700);

body {
    @include font-smoothing('antialiased');
    font-size: 14px;
    scroll-behavior: smooth;
    height: 100%;
}

html {
    overflow: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.slick-slide {
    outline: none;
}

a {
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}
