@media (max-width: 575.98px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    .mobile-menu {
        background: linear-gradient(244deg, $color-tertiary, $color-secondary);
        padding: 1rem;
        width: 55%;
        position: fixed;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        transform: translateX(-100%);
        transition: all ease 0.25s;
        z-index: 100;

        &.open {
            transform: translateX(0);
        }

        .navbar-nav {
            margin: 0;
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);

            .nav-link {
                font-size: 18px;
                color: #ffffff;
            }

            .btn {
                margin-top: 1rem;
            }
        }
    }

    .menu-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: rgba(0, 0, 0, 0.5);
        display: none;
        z-index: 99;

        &.open {
            display: block;
        }
    }

    .list-group--fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        z-index: 1000;
        box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.1);

        .list-group-item:last-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

@media (max-width: 767.98px) {
    .navbar {
        padding: 1rem;
    }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .section-header {
        &__card {
            color: white;
            border: none;
            box-shadow: 0px 5px 30px rgba(21, 198, 163, 0.5);
            background: url('../img/buble-1.png') 2% 25% no-repeat,
                url('../img/buble-2.png') 97% 90% no-repeat,
                url('../img/buble-3.png') 35% 65% no-repeat,
                url('../img/buble-4.png') 80% 10% no-repeat,
                linear-gradient(
                    253deg,
                    rgba(33, 198, 138, 1) 0%,
                    rgba(13, 198, 180, 1) 100%
                );
        }

        &__container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &__img {
            position: absolute;
            bottom: 0;
            left: 15%;
            width: 280px;
        }

        &__title {
            font-size: 28px;
        }

        &__btn {
            width: 52px;
            height: 52px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__btn-wrapper {
            margin: 0;
            position: absolute;
            bottom: 10%;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .section-feature {
        text-align: left;
    }

    .row {
        &.row-lg {
            margin: -20px;
            [class^='col'] {
                padding: 20px;
            }
        }

        &.row-blog {
            margin: -20px;
            [class^='col'] {
                padding: 20px;
            }
        }
    }

    .vertical-column {
        &__item:not(:last-child) {
            padding-bottom: 32px;
        }
    }

    .section-bottom {
        &__footer {
            background: $color-dark url('../img/footer.png') center center
                repeat;
        }

        &__img {
            display: block;
            width: 396px;
            left: 10%;
        }

        &__copy {
            text-align: left;
        }
    }
}

@media (min-width: 1200px) {
    .section-header {
        &__card {
            height: 320px;
        }
    }
}
