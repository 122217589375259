@mixin fontFace($family, $src, $style: normal, $weight: normal) {
    @font-face {
        font-family: $family;
        src: url('#{$src}.eot'); // IE9 compat
        src: url('#{$src}.eot?#iefix') format('embedded-opentype'),
            // IE8 and below
                url('#{$src}.woff') format('woff'),
            // standards
                url('#{$src}.woff2') format('woff2'),
            // standards
                url('#{$src}.ttf') format('truetype'),
            // Safari, Android, iOS
                url('#{$src}.svg##{$family}') format('svg'); // legacy iOS

        font-style: $style;
        font-weight: $weight;
    }
}

@mixin no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin font-smoothing($value: antialiased) {
    @if $value == antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

@mixin box-shadows($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

@mixin rounded($radius: 0.25rem) {
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    border-radius: 0.25rem;
}

@mixin multiline-ellipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    padding-right: 1em;
    &:before {
        content: '...';
        position: absolute;
        right: 40px;
        bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        right: 30px;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}
