.icon {
    display: inline-block;
    text-align: center;
    vertical-align: middle;

    &.icon-arrow-down {
        width: 24px;
        height: 24px;
        background: svg-load('../icon/down-arrow.svg', fill=#ffffff) no-repeat;
    }

    &.icon-chevron {
        width: 14px;
        height: 14px;
        background: svg-load('../icon/chevron.svg') no-repeat;
    }

    &.icon-check {
        width: 24px;
        height: 24px;
        background: svg-load('../icon/check.svg') no-repeat;
    }
}
